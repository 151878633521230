.whatCouldYouExpect {


  .containerImageTextPoint {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 800px;
    max-width:  calc(100vw - 40px);
    margin-top: 40px;
    gap: 50px;
    margin-bottom: 100px;

    .imageTextComponent {
      flex: 1;
      display: flex;
      flex-direction: column;
      max-width: 80vw;
      min-width: 250px;

      .imageTextComponentImg {
        width: 100%;
        border-radius: 10px;
        object-fit: contain;
      }

      .imageTextComponentTitle {
        font-size: 16px;
        margin-bottom: 0px;
      }

      .imageTextComponentText {
        font-size: 14px;
        opacity: 0.7;
      }
    }

  }
}

