.threeStepsApproach {

  .stepComponent {
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 80px;
    max-width: 100vw;
    flex-wrap: wrap;

    .textPart {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 320px;

      .tagStep {
        font-weight: 700;
        border-radius: 99px;
        font-size: 13px;
        padding: 7px 15px;
        background-color: rgba(61, 136, 228, 0.1);
        color: #3D88E4;
      }

      .titleStep {
        margin-bottom: 0;
        font-size: 20px;
      }

      .textStep {
        font-size: 14px;
        opacity: 0.5;
      }
    }

    .imageThreeSteps {
      width: 350px;
    }
  }

  @media (max-width: 768px) {

    .stepComponent {
      gap: 20px;
    }
  }

}


