.whatsAppFlotant {
  display: flex;
  align-items: center;
  position: fixed;   /* Changé de absolute à fixed */
  background-color: #25D366;
  color: #FFFFFF;
  bottom: 0;
  right: 0;
  margin-bottom: 20px;
  margin-right: 20px;
  padding: 7px 10px;
  border-radius: 99px;
  gap: 5px;
  font-weight: 600;
  font-size: 14px;
  transition: transform ease-in-out 0.2s;
  cursor: pointer;

  .waBSimpleIcon {
    width: 20px;
  }

  &:hover {
    transform: scale(1.02);
  }
}
