.questions {

  .containerQuestions {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    width: 100vw;
    align-items: center;

    .questionItem {
      width: 800px;
      max-width: 75vw;
      border: solid 1px #dddddd;
      border-radius: 10px;
      padding: 30px 30px;

      .questionText {
        margin-top: 0;
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 0px;
      }

      .answerText {
        opacity: 0.7;
        font-size: 14px;
        margin-bottom: 0;
      }

      .contactInfo {
        display: flex;
        align-items: center;
        color: #000;
        text-decoration: none;
        font-size: 13px;
        font-weight: 600;
        background-color: rgba(0, 0, 0, 0.1);
        padding: 6px 10px;
        border-radius: 30px 99px 99px 30px;


        .iconContactInfo {
          width: 22px;
          object-fit: contain;
          margin-right: 5px;
          border-radius: 5px;
          margin-left: -4px;
        }
      }
    }
  }

}
