// Colors
// 👇 TODO #7 : Change the main colors of your website

$color-primary: #007BFF;
$color-secondary: #55a7ff;
$color-tertiary: #9dcbff;
$color-light: #F8F9FA;
$color-dark: #343A40;
$color-gray: #6C757D;

// Typography
// 👇 TODO #8 : Change the font family of your website

$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif;

// Transitions
$transition-base: all 0.3s ease-in-out;

body {
  margin: 0rem;
  font-family: $font-family-base;
  background-color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: 500;
  word-wrap: break-word;
}

.containerLargeComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1200px;
  max-width:  calc(100vw - 40px);
  padding: 0px 20px;
}

.titlePart {
  margin-top: 80px;
  font-weight: 600;
  font-size: 35px;
  width: 550px;
  text-align: center;
  max-width: 90vw;

}

.subtitlePart {
  margin-top: -15px;
  font-size: 14px;
  font-weight: 500;
  width: 560px;
  max-width: 90vw;
  text-align: center;
}


.callToAction {
  margin-top: 20px;
  background-color: #FF5A5E;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  padding: 12px 40px;
  color: #FFFFFF;
  border: 0;
  transition: transform ease-in-out 0.2s;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
}

.subtitleCallToAction {
  font-size: 13px;
  text-decoration: none;
  color: #FF5A5E;
  font-weight: 600;
}


.subButton {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  margin-top: 10px;

  .people {
    width: 60px;
    object-fit: contain;
    margin-right: 10px;
  }

}

.dashedBar {
  width: 100%;
  border-top: dashed 2px #cfcfcf;

}

.tradeMark {
  display: flex;
  align-items: center;
  font-weight: 700;
}

.rights {
    font-weight: 500;
    font-size: 10px;
    opacity: 0.7;
  text-align: center;
}

@media (max-width: 768px) {
  .titlePart {
    font-size: 30px;
  }
}

.trustPilot {
  margin-top: 20px;
  width: 400px;
  object-fit: contain;
}
