.aSuccessfulApproach {

  .containerAvis {

    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    gap: 20px;
    max-width: 100vw;
    flex-wrap: wrap;

    .itemAvis {
      width: 400px;
      max-width: 90vw;
      object-fit: contain;
    }
  }

  @media (max-width: 768px) {

    .containerAvis {
      gap: 80px;
    }
  }
}
