.whichOneWouldYouChoose {

  .containerAnnonces {
    display: flex;
    align-items: center;
    margin-top: 40px;
    gap: 40px;
    max-width: 100vw;
    flex-wrap: wrap;
    justify-content: center;

    .annonce {
      width: 370px;
    }

    .vsComponent {
      font-weight: 700;
      font-size: 25px;
      display: flex;
      flex-direction: column;

    }
  }
}
