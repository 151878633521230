.lastCallToAction {

  .avatars {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;

    .containerItemAvatar {
      .name {
        font-size: 14px;
        font-weight: 600;
        text-align: center;
      }

      .title {
        font-size: 13px;
        width: 150px;
        text-align: center;
        opacity: 0.6;
      }

      .avatar {
        width: 150px;
        height: 150px;
        object-fit: cover;
        border-radius: 99px;
      }
    }

  }
}
