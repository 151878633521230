.landingPart {

  .newInDubai {
    display: flex;
    align-items: center;
    margin-top: 30px;
    background-color: rgba(28, 87, 221, 0.1);
    color: #1C57DD;
    border-radius: 99px;
    font-weight: 700;
    padding: 6px 12px;
    font-size: 14px;
    text-decoration: none;

    .network {
      width: 16px;
      object-fit: contain;
      margin-right: 6px;
      margin-left: -4px;
    }
  }

  .landingTitle {
    font-weight: 600;
    font-size: 50px;
    width: 550px;
    text-align: center;
    max-width: 90vw;
    gap: 10px;
    letter-spacing: -1px;
    margin-bottom: 20px;
  }

  .landingSubtitle {
    font-weight: 500;
    width: 600px;
    text-align: center;
    opacity: 0.7;
    max-width: 90vw;
    font-size: 14px;
  }

  .floorPlan {
    width: 600px;
    object-fit: contain;
    margin-top: 30px;
    max-width: 90vw;
  }



  .secondTitle {
    margin-top: 100px;
    font-weight: 600;
    font-size: 30px;
    width: 550px;
    text-align: center;
    max-width: 90vw;
    gap: 10px;
    letter-spacing: -1px;
    margin-bottom: 20px;
  }


}

@media (max-width: 768px) {

  .landingPart {
    .landingTitle {
      margin-top: 30px;
      font-size: 40px;
    }

    .landingSubtitle {
      font-size: 14px;
    }
  }

}
