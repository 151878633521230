.headerComponent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  max-width:  calc(100vw - 40px);
  padding: 0px 20px;

  .buttonOrderFloorPlan {
    background-color: #FF5A5E;
    border: 0;
    color: #FFFFFF;
    border-radius: 99px;
    font-size: 13px;
    font-weight: 600;
    padding: 8px 14px;
    transition: transform ease-in-out 0.2s;

    &:hover {
      cursor: pointer;
      transform: scale(1.02);
    }
  }
}
